import { navigate } from "gatsby";
import { Dispatch } from "react";
import { Action } from "./hooks/useForm";

type FormType = "donate" | "volunteer";

export async function _sendThankYouEmail(
  type: FormType,
  recipientEmail: string,
  recipientName: string
) {
  const res = await fetch(
    process.env["GATSBY_SERVICES_ENDPOINT"] + "/api/mailer/send?type=" + type,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        recipientEmail,
        recipientName
      })
    }
  );
  if (res.status >= 400) {
    throw new Error(
      "There was an error on our end. Please wait for a moment and retry."
    );
  }
}

export async function _addToSheet(type: FormType, formData: FormData) {
  const name = formData.get("formName"),
    email = formData.get("formEmail"),
    phone = formData.get("formPhone"),
    employed = formData.get("formEmployment"),
    institution = formData.get("formInstitution");

  const res = await fetch(
    process.env["GATSBY_SERVICES_ENDPOINT"] +
      "/api/form/support?formType=" +
      type,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        name: name.toString(),
        email: email.toString(),
        phone: phone.toString(),
        employed:
          (employed != null || employed !== "false") && employed?.toString(),
        institution: institution != null && institution?.toString()
      })
    }
  );
  if (res.status >= 400) {
    throw new Error(
      "There was an error on our end. Please wait for a moment and retry."
    );
  }
}

/** Serialize form and add to spreadsheet and send confirmation mail.  */
export async function handleSubmit(
  e: React.FormEvent<HTMLFormElement>,
  type: FormType,
  dispatch: Dispatch<Action>
) {
  e.preventDefault();
  dispatch({ type: "SetError", payload: undefined });
  dispatch({ type: "SetLoading", payload: true });
  const formData = new FormData(e.target as HTMLFormElement);
  const urlEncodedForm = new URLSearchParams(formData as any);

  try {
    await fetch("", {
      method: "POST",
      body: urlEncodedForm,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    });

    await _addToSheet(type, formData);
    await _sendThankYouEmail(
      type,
      urlEncodedForm.get("formEmail"),
      urlEncodedForm.get("formName")
    );
    // What if one fails but the other succeeds?

    dispatch({ type: "SetLoading", payload: false });

    navigate("/success", {
      state: {
        formType: type
      }
    });
  } catch (e) {
    console.error(e);
    dispatch({ type: "SetLoading", payload: false });
    dispatch({
      type: "SetError",
      payload: e instanceof Error ? e : new Error(e)
    });
  }
}
