import { useEffect, useState } from "react";

export function useFetch<T = Record<string, any>>(
  url: RequestInfo,
  key: string,
  options?: RequestInit
): [T, boolean] {
  const [response, setResponse] = useState<T>(null);
  const [error, setError] = useState(false);

  async function fetchData() {
    try {
      const res = await fetch(url, options);
      const json: T = await res.json();
      return json;
    } catch {
      setError(true);
    }
  }
  useEffect(() => {
    // caching
    if (
      localStorage.getItem(key) !== null &&
      localStorage.getItem(key) !== "undefined"
    ) {
      setResponse(JSON.parse(localStorage.getItem(key) || null));
      fetchData().then(json => {
        localStorage.setItem(key, JSON.stringify(json));
        setResponse(json);
      });
    } else {
      fetchData().then(json => {
        if (typeof json !== "undefined") {
          localStorage.setItem(key, JSON.stringify(json));
          setResponse(json);
        }
      });
    }
  }, [url, options]);
  return [response, error];
}
