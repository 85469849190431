import { useEffect, useState } from "react";
import { isBrowser } from "../utils";

let scrollMaxY: number;
let scrollRatio: number;

export function useScrollPosition() {
  const [scrollPercentage, setScrollPercentage] = useState(0);

  function handleScroll() {
    scrollMaxY =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    scrollRatio = parseFloat((window.pageYOffset / scrollMaxY).toFixed(2));

    setScrollPercentage(scrollRatio);
  }

  useEffect(() => {
    if (isBrowser) {
      window.addEventListener("scroll", handleScroll, { passive: true });
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, []);

  return scrollPercentage;
}
